import { Box, Typography } from "@mui/material";
import { registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import _ from "lodash";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "./css/Fileponde.css";
import Form from "./form/Form";
import { useEffect, useState } from "react";

registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateSize);

const App = () => {
  const [isLandscape, setIsLandscape] = useState(false);

  function isBrowserInLandscape() {
    const orientation = window.orientation;
    const isLandscape = Math.abs(orientation) === 90;

    return isLandscape;
  }

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsLandscape(isBrowserInLandscape());
    };

    // Initial check
    handleOrientationChange();

    window.addEventListener("orientationchange", handleOrientationChange);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  return (
    <div className="w-full lg:h-[100vh] h-[90vh] flex items-center justify-center">
      <div
        className={`sm:w-1/2 lg:w-1/3 w-[90%] ${
          isLandscape && "h-full"
        }  bg-black rounded-lg overflow-auto py-3`}
      >
        <Box className={"w-full flex flex-col items-center justify-center"}>
          <img src="iago.jpg" alt="S" />

        </Box>

        <Box className={"w-full py-2 pb-2"}>
          <Form />
        </Box>
      </div>
    </div>
  );
};

export default App;
