import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { LoadingButton } from "@mui/lab";
import QrCodeIcon from "@mui/icons-material/QrCode";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import _ from "lodash";
import axiosConfig from "../service/AxiosConfig";
import { Html5Qrcode } from "html5-qrcode";

registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

const Form = () => {
  const currentURL = window.location.href;
  const urlSearchParams = new URLSearchParams(
    decodeURIComponent(window.location.search)
  );
  const url = new URL(currentURL);
  let html5QrCode;

  const [_alert, _setAlert] = useState("");
  const [alertInfo, setAlertInfo] = useState(false);

  //useState created to prevent useEffect being executed
  const [remove, setRemove] = useState(false);
  const [_file, _setFile] = useState(true);

  const [openCamera, setOpenCamera] = useState(false);
  const [cameraAccess, setCameraAccess] = useState(false);

  const [_disabled, _setDisable] = useState(true);
  const [checkUrl, setCheckUrl] = useState(false);
  const [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    file_upload: yup.mixed().required(),
    ORIGEN: yup
      .string()
      // .max(25, "Sólo puede introducir 25 caracteres")
      .notRequired()
      .nullable(),
  });

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    trigger,
    reset,
    formState: { errors, dirtyFields, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      IDVINCULO: "",
      ORIGEN: "",
      file_upload: null,
    },
    resolver: yupResolver(schema),
  });

  const form = watch();

  useEffect(() => {
    if (openCamera) {
      if (!html5QrCode?.getState()) {
        html5QrCode = new Html5Qrcode("html5qr-code-full-region");
        const qrCodeSuccessCallback = (decodedText, decodedResult) => {
          const updatedURL = new URL(decodeURI(currentURL));

          updatedURL.search = "";

          window.history.replaceState({}, document.title, updatedURL.href);

          if (decodedText.includes("https") || decodedText.includes("http")) {
            const query_params = new URL(decodedText);

            const idVinculo = query_params.searchParams.get("IDVINCULO");
            const idOrigen = query_params.searchParams.get("ORIGEN");

            if (idVinculo) {
              setValue("IDVINCULO", idVinculo, {
                shouldDirty: true,
                shouldValidate: true,
              });
              trigger("IDVINCULO");

              if (idOrigen) {
                setValue("ORIGEN", idOrigen, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
                trigger("ORIGEN");
              }

              updatedURL.search = encodeURIComponent(query_params.search);

              window.history.replaceState({}, document.title, updatedURL.href);
            }
          } else {
            setValue("ORIGEN", "", {
              shouldDirty: false,
              shouldValidate: false,
            });
            trigger("ORIGEN");

            setValue("IDVINCULO", decodedText, {
              shouldDirty: true,
              shouldValidate: true,
            });
            trigger("IDVINCULO");

            updatedURL.searchParams.set(
              "IDVINCULO",
              encodeURIComponent(decodedText)
            );

            updatedURL.search = encodeURIComponent(`?IDVINCULO=${decodedText}`);

            window.history.replaceState({}, document.title, updatedURL.href);
          }

          setRemove(true);
          setCheckUrl(true);
          setAlertInfo(true);
          handleClose();
          html5QrCode.stop();
        };
        const config = {
          fps: 10,
          disableFlip: true,
        };

        html5QrCode
          .start(
            {
              facingMode: "environment",
            },
            config,
            qrCodeSuccessCallback
          )
          .catch((err) => {
            console.log(err);
            _setAlert(err);
          });
      }
    }
  }, [openCamera]);

  useEffect(() => {
    const idVinculoParam = urlSearchParams.get("IDVINCULO");
    const origenParam = urlSearchParams.get("ORIGEN");

    if (idVinculoParam) {
      setValue("IDVINCULO", idVinculoParam, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }

    if (origenParam) {
      setValue("ORIGEN", origenParam, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, []);

  useEffect(() => {
    const updatedURL = new URL(decodeURIComponent(currentURL));
    if (currentURL.includes("IDVINCULO")) {
      if (!remove) {
        const cleanedSearchParams = updatedURL.search.replace(/^\?+/g, "?");
        updatedURL.search = cleanedSearchParams;

        const value = updatedURL.searchParams.get("IDVINCULO") || "";
        setValue("IDVINCULO", value, {
          shouldDirty: true,
          shouldValidate: true,
        });
        trigger("IDVINCULO");
      }

      if (currentURL.includes("ORIGEN")) {
        const value = updatedURL.searchParams.get("ORIGEN") || "";
        setValue("ORIGEN", value, {
          shouldDirty: true,
          shouldValidate: true,
        });
        trigger("ORIGEN");
      }
    }

    currentURL.includes("IDVINCULO") ? setCheckUrl(true) : setCheckUrl(false);
  }, [currentURL]);

  const onSubmit = async (data) => {
    try {
      if (data.ORIGEN == "") {
        delete data.ORIGEN;
      }

      console.log(data);

      setLoading(true);

      const res = await axiosConfig.post(`https://47.iagocaamano.com/api/`, data);

      if (res.data?.status == "ERROR") {
        _setAlert("error");
      } else _setAlert("success");

      setTimeout(() => {
        _setAlert("");
        setAlertInfo(false);
      }, 1500);

      setValue("file_upload", null, {
        shouldDirty: false,
        shouldValidate: false,
      });

      trigger("file_upload");

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const removeUrl = () => {
    setValue("IDVINCULO", "", {
      shouldDirty: false,
      shouldValidate: false,
    });
    trigger("IDVINCULO");
    setValue("ORIGEN", "", {
      shouldDirty: false,
      shouldValidate: false,
    });
    trigger("ORIGEN");

    const updatedURL = new URL(currentURL);

    updatedURL.search = "";

    window.history.replaceState({}, document.title, updatedURL.href);

    setCheckUrl(false);
    setAlertInfo(false);
  };

  const handleOpen = () => {
    setOpenCamera(true);
  };

  const handleClose = () => {
    setOpenCamera(false);
  };

  return (
    <>
      {openCamera && (
        <div className="w-full h-[100vh] flex flex-col items-center justify-center  top-0 left-0 !z-50  bg-black !fixed  shadow-2xl !overflow-auto px-5 py-7">
          {_alert ===
          "Error getting userMedia, error = NotFoundError: Requested device not found" ? (
            <Typography className="px-10 text-md font-semibold text-white !pt-[100] absolute">
              Cámara no ha sido encontrada
            </Typography>
          ) : _alert ===
            "Error getting userMedia, error = NotAllowedError: Permission denied" ? (
            <Typography className="px-10 text-md font-semibold text-white !pt-[100] absolute">
              Faltan permisos de cámara
            </Typography>
          ) : (
            <Typography className="px-10 text-md font-semibold text-white !pt-[100] absolute">
              {_alert}
            </Typography>
          )}

          <div
            id={"html5qr-code-full-region"}
            className="md:w-[50%] md:h-[50%] lg::w-[60%] :h-[60%] w-[70%] h-[70%] flex items-center justify-center md:mb-40 mb-10"
          />

          <Button
            onClick={handleClose}
            variant="contained"
            className="!fixed !bottom-5"
            // className="md:!mt-36 !-mt-5"
          >
            Cancelar
          </Button>
        </div>
      )}
      <div className={"w-full h-full flex flex-col items-center px-10 z-10"}>
        <div className="w-full my-5 flex flex-col items-center justify-center space-y-5">
          {urlSearchParams.get("DESCQR") && (
            <div className="bg-[#c9c9c9] p-3  items-center w-full text-center">
              <Typography
                variant="h7 text-white"
                className="flex items-center justify-center break-all overflow-hidden text-ellipsis text-center"
              >
                {urlSearchParams.get("DESCQR")}
              </Typography>
            </div>
          )}

          <Typography variant="h7 text-white text-center font-['Brougham']">Sube tu foto para memorizar esta fecha especial para mi</Typography>
        </div>

        {alertInfo == true && (
          <Alert severity="info" className="mt-5 ">
            <Typography>
              Código Qr escaneado correctamente, insertar archivo ou{" "}
              <span className="underline cursor-pointer	" onClick={removeUrl}>
                Cancelar
              </span>
            </Typography>
          </Alert>
        )}
        <div className="w-full">
          

          <Controller
            control={control}
            name="file_upload"
            render={({ field: { value, onChange, ...field } }) => (
              <FilePond
                {...field}
                allowImagePreview
                allowFileSizeValidation
                allowFileTypeValidation
                imagePreviewMaxHeight={100}
                credits={false}
                maxFileSize={"50MB"}
                files={value ? [value] : []}
                labelIdle={`Clique para agregar archivos`}
                onaddfile={(e, file) => {
                  const max = 50 * 1024 * 1024;

                  file.fileSize < max ? _setFile(false) : _setFile(true);

                  setValue("file_upload", file.file, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                  trigger("file_upload");
                }}
                onremovefile={(e, file) => {
                  setValue("file_upload", null, {
                    shouldDirty: false,
                    shouldValidate: false,
                  });

                  trigger("file_upload");
                }}
                className={"relative, break-words block mt-5"}
                labelMaxFileSizeExceeded="El archivo es demasiado grande"
                labelMaxFileSize="El archivo sólo puede ser de hasta 50MB"
              />
            )}
          />
        </div>

        <LoadingButton
          type="submit"
          variant="contained"
          // sx={{ backgroundColor: "#f32735" }}
          loading={loading}
          // className="bg-[#f32735]"
          fullWidth
          onClick={handleSubmit(onSubmit)}
          disabled={_.isEmpty(dirtyFields) || !isValid || _file}
        >
          <Typography>Enviar</Typography>
        </LoadingButton>

        {_alert == "success" && (
          <div className="w-full h-20  mt-5">
            <Alert variant="filled" severity="success">
              Archivo enviado con éxito
            </Alert>
          </div>
        )}
        {_alert == "error" && (
          <div className="w-full h-20 mt-5">
            <Alert variant="filled" severity="error">
              Error al procesar archivo. Intente de nuevo
            </Alert>
          </div>
        )}
      </div>
    </>
  );
};

export default Form;
