import axios from "axios";

const axiosConfig = axios.create({
  // baseURL: "https://fotos.stac.es/api",
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export default axiosConfig;
